/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br",
    a: "a",
    h3: "h3",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "TFLiteモデルのベンチマークをとりたい時に使えるツールをTensorFlowが公式で出してくれているので、使い方のメモ。", React.createElement(_components.br), "\n", "TFLiteモデルをAndroid端末上で動かしてパフォーマンス測定したい時に、サンプルプログラムとかを書かなくて済むので、とても便利。", React.createElement(_components.br), "\n", "使う手順や実行時の詳しいオプションなどは、", React.createElement(_components.a, {
    href: "https://www.tensorflow.org/lite/performance/measurement"
  }, "公式ドキュメント"), "と", React.createElement(_components.a, {
    href: "https://github.com/tensorflow/tensorflow/tree/master/tensorflow/lite/tools/benchmark"
  }, "Githubリポジトリ"), "を参照すると書いてある。\nベンチマークをとるのには、Android benchmark appとNative benchmark binaryの２種類の方法があるが、今回は、後者を使う。"), "\n", React.createElement(_components.h3, null, "ビルド済みバイナリのダウンロード"), "\n", React.createElement(_components.p, null, "まず", React.createElement(_components.a, {
    href: "https://www.tensorflow.org/lite/performance/measurement#download_or_build_the_binary"
  }, "公式ドキュメント"), "に、\nそれぞれのプラットフォーム向けにビルド済みのバイナリをダウンロードできるリンクが貼ってあるので、そこからバイナリファイルをダウンロードする。", React.createElement(_components.br), "\n", "私はGoogle Pixel 6上で動かすので、android_aarch64版をダウンロード。", React.createElement(_components.br), "\n", "ABIがarmeabi-v7aのスマホは、android_armのリンクから、arm64-v8aのスマホは、android_aarch64のリンクからダウンロードすれば良いと思われる。", React.createElement(_components.br), "\n", "Flex delegateやHexagon delegate用にいろいろなバージョンが用意されているが、今回試したのはノーマルのバージョンだけ。"), "\n", React.createElement(_components.h3, null, "バイナリを端末にpushして実行権限付与"), "\n", React.createElement(_components.p, null, "Android端末をUSBケーブルでPCに繋いでおく。\n以下のコマンドでダウンロードしたバイナリファイルをAndroid端末上にpushして、実行権限を付与する。", React.createElement(_components.br), "\n", "3行目のコマンドを実行しないと、Permission deniedエラーが出るので注意。"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "adb shell mkdir -p /data/local/tmp/tfliteexample\nadb push ./android_aarch64_benchmark_model /data/local/tmp/tfliteexample/\nadb shell chmod +x /data/local/tmp/tfliteexample/android_aarch64_benchmark_model\n")), "\n", React.createElement(_components.h3, null, "計測対象のTFLiteモデルをpush"), "\n", React.createElement(_components.p, null, "以下のコマンドでTFLiteモデルをAndroid端末にpush。\nいくつかのサンプルモデルは、", React.createElement(_components.a, {
    href: "https://www.tensorflow.org/lite/performance/measurement#android_performance_benchmarks"
  }, "公式ドキュメント"), "からダウンロードできる。"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "adb push ./mobilenet_v1_1.0_224_quant.tflite /data/local/tmp/tfliteexample/\n")), "\n", React.createElement(_components.h3, null, "ベンチマークツールを実行する"), "\n", React.createElement(_components.p, null, "以下のコマンドでベンチマークツールを実行する。\n", React.createElement(_components.code, null, "--use_gpu=true"), "や", React.createElement(_components.code, null, "--use_nnapi=true"), "などのオプションで特定のdelegateを適用できるし、\n", React.createElement(_components.code, null, "--enable_op_profiling=true"), "のオプションを使うと、各オペレーションの実行時間が表示されるので、\nどのオペレーションに時間がかかっているかがわかる。"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "adb shell /data/local/tmp/tfliteexample/android_aarch64_benchmark_model \\\n    --graph=/data/local/tmp/tfliteexample/mobilenet_v1_1.0_224_quant.tflite \\\n    --use_nnapi=true \\\n    --enable_op_profiling=true\n")), "\n", React.createElement(_components.h3, null, "補足"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ベンチマークツールで実行した速度と実際にアプリで実行した場合の速度は異なる場合がある。"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
